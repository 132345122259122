import { useTranslation } from "react-i18next";

import ABOUT from '../../assets/images/about/about.jpg'
import { Helmet } from "react-helmet";

const About = () => {
    const { t } = useTranslation('about');

    return (
        <>
            <Helmet>
                <title>About</title>
                <meta name="description" content="Discover the Featy Story - Our Origin and Core Values." />
            </Helmet>
            <section id="content" className="s-content">
                <section className="s-pageheader pageheader">
                    <div className="row">
                        <div className="column xl-12">
                            <h1 className="page-title">
                                <span className="page-title__small-type text-pretitle">{t('about:subtitle')}</span>
                                {t('about:title')}
                            </h1>
                        </div>
                    </div>
                </section>

                <section className="s-pagecontent pagecontent">
                    <div className="row width-narrower pagemain">
                        <div className="column xl-12">
                            <p dangerouslySetInnerHTML={{ __html: t('about:text1') }} />
                            <p className="u-remove-bottom">{t('about:text2')}</p>
                            <p dangerouslySetInnerHTML={{ __html: t('about:text3') }} />
                            <p>{t('about:text4')}</p>

                            <ul className="list-services">
                                <li>
                                    <span className="list-items__item-small-title">{t('about:adaptability.title')}</span>
                                    {` ${t('about:adaptability.text')}`}
                                </li>
                                <li>
                                    <span className="list-items__item-small-title">{t('about:fun.title')}</span>
                                    {` ${t('about:fun.text')}`}
                                </li>
                                <li>
                                    <span className="list-items__item-small-title">{t('about:energy.title')}</span>
                                    {` ${t('about:energy.text')}`}
                                </li>
                            </ul>
                        </div>

                        <img src={ABOUT} alt="about" />
                    </div>
                </section>
                <section className="s-info">
                    <div className="row width-narrower ">
                        <div className="column xl-12">
                            <h2>{t('about:aboutme.title')}</h2>
                            <p dangerouslySetInnerHTML={{ __html: t('about:aboutme.text') }} />

                            <h2>{t('about:values.title')}</h2>
                            <p>
                                <span className="list-items__item-small-title">{t('about:values.passion.title')}: </span>
                                {` ${t('about:values.passion.text')}`}
                            </p>
                            <p>
                                <span className="list-items__item-small-title">{t('about:values.commitment.title')}: </span>
                                {` ${t('about:values.commitment.text')}`}
                            </p>
                            <p>
                                <span className="list-items__item-small-title">{t('about:values.professionalism.title')}: </span>
                                {` ${t('about:values.professionalism.text')}`}
                            </p>
                            <p>
                                <span className="list-items__item-small-title">{t('about:values.listening.title')}: </span>
                                {` ${t('about:values.listening.text')}`}
                            </p>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

export default About;