import { useTranslation } from "react-i18next"
import { BsArrowUp } from 'react-icons/bs';
import { email, phoneNumber } from "../../core/constants";

import LOGO_MINIMAL from '../../assets/logo_minimal.png'

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="s-footer">
			<div className="s-footer__logo">
				<img src={LOGO_MINIMAL} alt="logo-minimal-featy" />
			</div>
			<div className="s-footer__content">
				<p className="ss-copyright">
					<span className="ss-copyright__delimiter">©2023 Featy Coaching</span>
					<span>{t('footer.design_by')} <a href="https://jeromegallego.fr/" target="_blank" rel="noreferrer">Jérôme GALLEGO</a></span>
				</p>
				<div className="s-footer__infos">
					<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
					<a href={`mailto:${email}`}>{email}</a>
				</div>
			</div>
			<div className="ss-go-top">
				<a className="smoothscroll" title="Back to Top" href="#top">
					<BsArrowUp />
				</a>
			</div>
		</footer>
	)
}

export default Footer
