import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LOGO from '../../assets/logo.png'
import { NavLink, useLocation, } from 'react-router-dom';

const HeaderDesktop = () => {
	const { t } = useTranslation();

	const location = useLocation();
	const isContactPage = location.pathname === '/contact';

	const [isClicked, setIsClicked] = useState(false);

	const onClose = () => {
		setIsClicked(false);
	};

	return (
		<header className={`s-header ${isClicked ? 'menu-is-open' : ''}`}>
			<div className="row s-header__inner width-sixteen-col">
				<div className="s-header__block">
					<div className="s-header__logo">
						<NavLink to={"/"} className="logo" onClick={() => { onClose(); }}>
							<img src={LOGO} alt="logo-featy" />
						</NavLink>
					</div>
					<a
						className={`s-header__menu-toggle ${isClicked ? 'is-clicked' : ''}`}
						href="#0"
						onClick={() => {
							setIsClicked(!isClicked);
						}}
					>
						<span>Menu</span>
					</a>
				</div>

				<nav className="s-header__nav">
					<ul className="s-header__menu-links">
						<li><NavLink to="about" onClick={() => { onClose(); }}>{t('menu.about')}</NavLink></li>
						<li><NavLink to="services" onClick={() => { onClose(); }}>{t('menu.services')}</NavLink></li>
						<li><NavLink to="contact" onClick={() => { onClose(); }}>{t('menu.contact')}</NavLink></li>
					</ul>
					{!isContactPage && (
						<div className="s-header__contact">
							<NavLink to="contact" className="btn btn--primary s-header__contact-btn" onClick={() => { onClose(); }}>{t('menu.cta')}</NavLink>
						</div>
					)}
				</nav>
			</div>
		</header>
	)
}

export default HeaderDesktop
