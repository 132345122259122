import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';

import Header from './components/header';
import Footer from './components/footer';

import { routes } from './routes';

import './assets/css/styles.css';

const App = () => {
	return (
		<Suspense fallback={<></>}>
			<BrowserRouter>
				<Header />

				<div className={`app-content`}>
					<Routes>
						{routes.map(route => {
							return <Route
								path={route.path}
								element={route.component}
								key={`routes_${route.path}`}
							/>
						})}
					</Routes>
				</div>

				<Footer />
			</BrowserRouter>
		</Suspense>
	)
}

export default App