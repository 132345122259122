import { useTranslation } from "react-i18next";

import SERVICE1 from '../../assets/images/services/service1.jpg'
import SERVICE2 from '../../assets/images/services/service2.jpg'
import SERVICE3 from '../../assets/images/services/service3.jpg'
import SERVICE4 from '../../assets/images/services/service4.jpg'
import { Helmet } from "react-helmet";

const Services = () => {
    const { t } = useTranslation('services');

    return (
        <>
            <Helmet>
                <title>Services</title>
                <meta name="description" content="Discover the range of outdoor sessions and activities offered by Featy." />
            </Helmet>
            <section id="content" className="s-content">
                <section className="s-pageheader pageheader">
                    <div className="row">
                        <div className="column xl-12">
                            <h1 className="page-title">
                                <span className="page-title__small-type text-pretitle">{t('services:subtitle')}</span>
                                {t('services:title')}
                            </h1>
                        </div>
                    </div>
                </section>
                <section className="s-pagecontent pagecontent">
                    <div className="row">
                        <div className="column xl-12 grid-block">
                            <div className="grid-full grid-list-items list-items show-ctr">
                                <div className="grid-list-items__item list-items__item">
                                    <div className="list-items__item-header">
                                        <h3 className="list-items__item-title">{t('services:group_sessions.title')}</h3>
                                    </div>
                                    <div className="list-items__item-text">
                                        <p>{t('services:group_sessions.text')}</p>


                                        <p className="list-items__item-small-title">{t('services:benefits')}:</p>
                                        <p>{t('services:group_sessions.benefits')}</p>

                                        <ul className="list-services">
                                            <li>{t('services:group_sessions.schedule1')}</li>
                                            <li>{t('services:group_sessions.schedule2')}</li>
                                        </ul>


                                        <p className="list-items__item-small-title">{t('services:pricing')}:</p>
                                        <p className="u-remove-bottom">{t('services:group_sessions.trial_session')} <span className="list-items__item-small-title">{t('services:group_sessions.for_free')}</span></p>
                                        <p>{t('services:group_sessions.pricing')}</p>
                                        <p>{t('services:group_sessions.pricing_bundle')}</p>
                                    </div>
                                </div>
                                <div className="grid-list-items__item list-items__item">
                                    <div className="list-items__item-header">
                                        <h3 className="list-items__item-title">{t('services:private_sessions.title')}</h3>
                                    </div>
                                    <div className="list-items__item-text">
                                        <p>{t('services:private_sessions.text')}</p>
                                        <p className="list-items__item-small-title">{t('services:pricing')}:</p>
                                        <p>{t('services:private_sessions.pricing')}</p>
                                    </div>
                                </div>
                                <div className="grid-list-items__item list-items__item">
                                    <div className="list-items__item-header">
                                        <h3 className="list-items__item-title">{t('services:additional_activities.title')}</h3>
                                    </div>
                                    <div className="list-items__item-text">
                                        <p>{t('services:additional_activities.text')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="s-gallery">
                    <div className="grid-list-items list-items row width-narrower">
                        <div className="grid-list-items__item list-items__item u-remove-bottom">
                            <img src={SERVICE1} alt="service_1" />
                        </div>
                        <div className="grid-list-items__item list-items__item u-remove-bottom">
                            <img src={SERVICE2} alt="service_2" />
                        </div>
                        <div className="grid-list-items__item list-items__item u-remove-bottom">
                            <img src={SERVICE3} alt="service_3" />
                        </div>
                        <div className="grid-list-items__item list-items__item u-remove-bottom">
                            <img src={SERVICE4} alt="service_4" />
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

export default Services;