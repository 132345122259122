import { useTranslation } from "react-i18next"

import HOME from '../../assets/images/home/home.jpg'
import { BsArrowDown, BsInstagram } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import { Helmet } from "react-helmet"

const Home = () => {
    const { t } = useTranslation('home')

    return (
        <>
            <Helmet>
                <title>Featy Coaching  - Your Hub for Outdoor Sports, Adventure, and Wellness</title>
                <meta name="description" content="Join the Featy community to explore an active, healthy, and adventure-filled life in the great outdoors." />
            </Helmet>
            <section id="content" className="s-content">
                <section id="intro" className="s-intro">
                    <div className="row s-intro__content width-sixteen-col">
                        <div className="column lg-12 s-intro__content-inner grid-block">
                            <div className="s-intro__content-text">
                                <div className="s-intro__content-pretitle text-pretitle">{t('home:subtitle')}</div>
                                <h1 className="s-intro__content-title">{t('home:title')}</h1>
                            </div>

                            <div className="s-intro__content-media">
                                <div className="s-intro__content-media-inner">
                                    <img src={HOME} alt="home" />
                                </div>
                            </div>

                            <div className="s-intro__scroll-down">
                                <a href="#about" className="smoothscroll">
                                    <div className="scroll-icon">
                                        <BsArrowDown />
                                    </div>
                                    <span>Scroll for more</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about" className="s-about target-section">
                    <div className="row s-about__content width-sixteen-col">
                        <div className="column grid-block grid-section-split">
                            <div className="section-header grid-section-split__header">
                                <div className="text-pretitle">{t('home:about.subtitle')}</div>
                                <h2 className="text-display-title">{t('home:about.title')}</h2>
                            </div>

                            <div className="s-about__content-main grid-section-split__primary">
                                <p className="attention-getter" dangerouslySetInnerHTML={{ __html: t('home:about.text1') }} />

                                <p className="attention-getter">
                                    {t('home:about.text2')}
                                </p>

                                <p className="attention-getter" dangerouslySetInnerHTML={{ __html: t('home:about.text3') }} />
                            </div>

                            <div className="s-about__content-btn grid-section-split__bottom">
                                <a href="about.html" className="btn btn--stroke u-fullwidth">{t('home:about.button')}</a>

                                <ul className="s-about__social social-list">
                                    <li>
                                        <a href="https://www.instagram.com/featy_coaching/" target="_blank" rel="noreferrer">
                                            <BsInstagram />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="expertise" className="s-expertise">
                    <div className="row s-expertise__content width-sixteen-col">
                        <div className="column xl-12 grid-block grid-section-split">
                            <div className="section-header grid-section-split__header">
                                <div className="text-pretitle">{t('home:services.subtitle')}</div>
                                <h2 className="text-display-title">
                                    {t('home:services.title')}
                                </h2>
                            </div>

                            <div className="s-expertise__content-main grid-section-split__primary">
                                <div className="grid-list-items list-items show-ctr">
                                    <div className="grid-list-items__item list-items__item">
                                        <div className="grid-list-items__title list-items__item-header">
                                            <h3 className="list-items__item-title">{t('home:services.group_sessions.title')}</h3>
                                        </div>
                                        <div className="grid-list-items__text list-items__item-text">
                                            <p>
                                                {t('home:services.group_sessions.text')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid-list-items__item list-items__item">
                                        <div className="grid-list-items__title list-items__item-header">
                                            <h3 className="list-items__item-title">{t('home:services.private_sessions.title')}</h3>
                                        </div>
                                        <div className="grid-list-items__text list-items__item-text">
                                            <p>
                                                {t('home:services.private_sessions.text')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid-list-items__item list-items__item">
                                        <div className="grid-list-items__title list-items__item-header">
                                            <h3 className="list-items__item-title">{t('home:services.special_activities.title')}</h3>
                                        </div>
                                        <div className="grid-list-items__text list-items__item-text">
                                            <p>
                                                {t('home:services.special_activities.text')}
                                            </p>
                                            <ul className="list-services">
                                                <li>
                                                    {t('home:services.special_activities.hiking')}
                                                </li>
                                                <li>
                                                    {t('home:services.special_activities.paddle')}
                                                </li>
                                                <li>
                                                    {t('home:services.special_activities.beach')}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="s-expertise__content-btn grid-section-split__bottom">
                                <NavLink to="services" className="btn btn--stroke u-fullwidth">{t('home:services.button')}</NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="testimonials" className="s-testimonials">
                    <div className="row s-testimonials__content width-sixteen-col">
                        <div className="xl-12 grid-block grid-section-split">
                            <div className="section-header grid-section-split__header">
                                <div className="text-pretitle">{t('home:testimonials.subtitle')}</div>
                                <h2 className="text-display-title">
                                    {t('home:testimonials.title')}
                                </h2>
                            </div>
                        </div>

                        <div className="row s-testimonials__content">
                            <div className="column xl-12 testimonials">
                                <div className="swiper-container testimonials__slider page-slider">
                                    <div className="testimonials__slide swiper-slide">
                                        <p>
                                            It’s outdoors, adapted to all levels and with a super enthusiastic, patient, yet motivating coach. Every class is different with Marie but they have one thing in common: good vibes and so much fun! She’ll make you challenge yourself and you won’t even notice you’re working hard (only maybe the next day ;))
                                        </p>
                                        <div className="testimonials__author">
                                            <cite className="testimonials__cite">
                                                <strong>Borbi</strong>
                                            </cite>
                                        </div>
                                    </div>
                                    <div className="testimonials__slide swiper-slide">
                                        <p>
                                            Desde que empecé a entrenar con Marie he mejorado mi flexibilidad, mi postura y mi fuerza, además de mi físico desde el punto de vista estético. Sus clases son en contacto con la naturaleza, muy dinámicas y siempre tiene un enfoque personalizado para mi cuerpo y mis necesidades. Siguiendo sus consejos no solamente he perdido peso, sino que también me ha ayudado a mejorar mi alimentación, mis hábitos de sueño y mi energía durante el día.
                                        </p>
                                        <div className="testimonials__author">
                                            <cite className="testimonials__cite">
                                                <strong>Yudet</strong>
                                            </cite>
                                        </div>
                                    </div>
                                    <div className="testimonials__slide swiper-slide">
                                        <p>
                                            Marie es una excelente profesional, simpática y atenta. Se implica con su alumnos, y de una forma didáctica te explica los ejercicios a realizar para su mejor comprensión. También da consejos personalizados a cada uno, en fin una instructora de 10.
                                        </p>
                                        <div className="testimonials__author">
                                            <cite className="testimonials__cite">
                                                <strong>Daniel</strong>
                                            </cite>
                                        </div>
                                    </div>
                                    <div className="testimonials__slide swiper-slide">
                                        <p>
                                            Thank you Marie for the coaching and your support in my beginnings of running. You knew how to perfectly supervise and guide me throughout my teaching. Thanks to this I was able to develop a taste for this sport and I found pleasure in doing a sport again without forcing myself!
                                        </p>
                                        <div className="testimonials__author">
                                            <cite className="testimonials__cite">
                                                <strong>Jérôme</strong>
                                            </cite>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Home