import { BsInstagram } from "react-icons/bs";
import { email, phoneNumber } from "../../core/constants";
import { useTranslation } from "react-i18next";

import ME from '../../assets/images/contact/me.jpeg'
import { Helmet } from "react-helmet";

const Contact = () => {
	const { t } = useTranslation(['common', 'contact']);

	return (
		<>
			<Helmet>
				<title>Contact</title>
				<meta name="description" content="Get in touch with Featy for any questions or information related to our outdoor sports and coaching offerings." />
			</Helmet>
			<section id="content" className="s-content">
				<section className="s-pageheader pageheader">
					<div className="row">
						<div className="column xl-12">
							<h1 className="page-title">
								<span className="page-title__small-type text-pretitle">{t('contact:subtitle')}</span>
								{t('contact:title')}
							</h1>
						</div>
					</div>
				</section>

				<section className="s-pagecontent pagecontent">
					<div className="row width-narrower pagemain">
						<div className="column xl-12">
							<h2>{t('contact:text')}</h2>

							<div className="row">
								<div className="column xl-6 md-12 contact-cta">
									<p>{t('contact:explanations')}</p>

									<div className="contact-block">
										<h6>{t('contact:follow_social')}</h6>
										<ul className="contact-social social-list">
											<li>
												<a href="https://www.instagram.com/featy_coaching/" target="_blank" rel="noreferrer">
													<BsInstagram />
												</a>
											</li>
										</ul>
									</div>

									<div className="contact-block">
										<h6>{t('common:email')}</h6>
										<a href={`mailto:${email}`}>{email}</a>
									</div>

									<div className="contact-block">
										<h6>{t('common:phone')}</h6>
										<ul className="contact-list">
											<li><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></li>
										</ul>
									</div>

									<a href={`mailto:${email}`} className="btn btn--primary u-fullwidth contact-btn">
										{t('contact:send_email')}
									</a>
								</div>
								<div className="column xl-5 md-12 u-flexitem-x-right">
									<img src={ME} alt="contact-me" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</section>
		</>
	);
};

export default Contact