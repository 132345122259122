import About from './pages/about';
import Contact from './pages/contact';
import Home from './pages/home';
import Services from './pages/services';


export const routes = [
    { path: '/', component: <Home />, name: 'accueil' },
    { path: '/about', component: <About />, name: 'about' },
    { path: '/services', component: <Services />, name: 'services' },
    { path: '/contact', component: <Contact />, name: 'contact' },
]